import React from 'react';
import SEO from '../components/seo';
import styled from 'styled-components';
import Layout from '../components/layout';
import CabinsOverviewImage from '../images/cabins/cabins-overview-2.jpg';
import CampsiteOverview_Img from '../images/campsites-overview.jpg';
import BasicCabin_1_Img from '../images/cabins/new/cabin-basic-2-to-6-1.jpg';
import BasicCabin_2_Img from '../images/cabins/new/cabin-basic-2-to-6-2.jpg';

import BasicCabinNew_1_Img from '../images/cabins/new/cabin-basic-new-1.jpg';
import BasicCabinNew_2_Img from '../images/cabins/new/cabin-basic-new-2.jpg';
import BasicCabinNew_3_Img from '../images/cabins/new/cabin-basic-new-3.jpg';

import Deluxe2_1_Img from '../images/cabins/new/cabin-deluxe-2-to-3-1.jpg';
import Deluxe2_2_Img from '../images/cabins/new/cabin-deluxe-2-to-3-2.jpg';

import Deluxe2To3_1_Img from '../images/cabins/new/cabin-deluxe-2-to-4-1.jpg';
import Deluxe2To3_2_Img from '../images/cabins/new/cabin-deluxe-2-to-4-2.jpg';
import Deluxe2To3_3_Img from '../images/cabins/new/cabin-deluxe-2-to-4-3.jpg';

import CabinTinyHome2Ppl_1_Img from '../images/cabins/new/cabin-tiny-home-2-people-1.jpg';
import CabinTinyHome2Ppl_2_Img from '../images/cabins/new/cabin-tiny-home-2-people-2.jpg';
import CabinTinyHome2Ppl_3_Img from '../images/cabins/new/cabin-tiny-home-2-people-3.jpg';

import CabinTinyHomeQueen_1_Img from '../images/cabins/new/cabin-tiny-home-2-queens-1.jpg';
import CabinTinyHomeQueen_2_Img from '../images/cabins/new/cabin-tiny-home-2-queens-2.jpg';
import CabinTinyHomeQueen_3_Img from '../images/cabins/new/cabin-tiny-home-2-queens-3.jpg';
import CabinTinyHomeQueen_4_Img from '../images/cabins/new/cabin-tiny-home-2-queens-4.jpg';

import PoweredSitesImage from '../images/powered-sites.jpg';
import CampEntry from '../images/camp-entry.jpeg';
import { ImageGrid3Cabin } from '../components/image-grid';
import { paragraphMixin, subheadingMixin, OutboundButton } from '../theme/mixins';
import { PageBanner } from '../components/page-banner';

const GeneralSection = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: 120px 100px 62px 100px;

  @media only screen and (max-width: 1000px) {
    margin: 64px 30px 30px 30px;
    grid-template-columns: 1fr;
    width: calc(100% - 60px);
  }

  img {
    border-radius: 16px;
    width: 100%;
    height: 400px;
    @media only screen and (max-width: 1000px) {
      height: 300px;
    }
    object-fit: cover;
  }

  p {
    ${paragraphMixin}
  }
`;

const BookingPolicySection = styled(GeneralSection)`
  ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-bottom: auto;
    li {
      ${paragraphMixin}
    }
    @media only screen and (max-width: 1000px) {
      margin-bottom: 32px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    a {
      max-width: 180px;
    }
  }
`;

const PoweredSitesSection = styled(GeneralSection)`
  @media only screen and (max-width: 1000px) {
    .text-content {
      order: 2;
    }
  }
`;

const CabinSection = styled(GeneralSection)`
  @media only screen and (max-width: 1000px) {
    .text-content {
      order: 2;
    }
  }
  .cabin-description {
    margin-bottom: 24px;
  }
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 30px 100px 62px 100px;

  @media only screen and (max-width: 1000px) {
    margin: 20px 30px 42px 30px;
  }
  p {
    ${paragraphMixin}
  }
`;

const GridList = styled.div`
  display: grid;
  grid-template-columns: 200px 200px;
  grid-gap-column: 24px;

  @media only screen and (max-width: 700px) {
    grid-template-columns: auto;
  }

  ul {
    list-style-type: circle;
    list-style-position: inside;
  }
`;

const Subheading = styled.h2`
  ${subheadingMixin}
  margin-bottom: 24px;

  @media only screen and (max-width: 1000px) {
    margin-bottom: 12px;
  }
`;

const AccommodationPage = () => {
  const subCabins = [
    {
      images: [CabinTinyHome2Ppl_1_Img, CabinTinyHome2Ppl_2_Img, CabinTinyHome2Ppl_3_Img],
      title: 'Tiny Homes Ensuite Cabin',
      description1: 'Sleeps 2 people',
      description2:
        'Cosy cabin for 2 people which has a sitting area and kitchen with a full fridge. Queen bed with linen and ensuite. Beautiful decking area to relax.',
    },
    {
      images: [
        CabinTinyHomeQueen_1_Img,
        CabinTinyHomeQueen_2_Img,
        CabinTinyHomeQueen_3_Img,
        CabinTinyHomeQueen_4_Img,
      ],
      title: 'Tiny Homes Ensuite Cabin',
      description1: 'Sleeps 2 or 2 couples (2 cabins only)',
      description2:
        'Spacious cabin which is very versatile. 2 queen beds with linen, kitchen with a full fridge, living room and ensuite. Beautiful decking to relax and enjoy the view of Victoria Park.',
    },
    {
      images: [BasicCabinNew_1_Img, BasicCabinNew_2_Img, BasicCabinNew_3_Img],
      title: 'Basic Ensuite Cabin',
      description1: 'Sleeps 2 to 6 people',
      description2:
        'Bedding is provided for the Double bed and additional bedding is required only for the bunk beds',
    },
    {
      images: [BasicCabin_2_Img, BasicCabin_1_Img],
      title: 'Basic Ensuite Cabins',
      description1: 'Sleeps 2 people (3 cabins only)',
      description2:
        'Bedding is provided for the Double bed and additional bedding is required only for the bunk beds',
    },
    {
      images: [Deluxe2_2_Img, Deluxe2_1_Img],
      title: 'Deluxe Ensuite Cabin',
      description1: 'Sleeps 2 to 3 people',
      description2: 'Modern and spacious room with linen supplied to all beds and ensuite',
    },
    {
      images: [Deluxe2To3_1_Img, Deluxe2To3_2_Img, Deluxe2To3_3_Img],
      title: 'Deluxe Ensuite Cabin',
      description1: 'Sleeps 2 to 4 people',
      description2:
        'Modern and spacious room with linen supplied to all beds and ensuite. Separate bedroom with 2 single beds',
    },
  ];

  return (
    <Layout>
      <SEO title="Accommodation" />
      <PageBanner title="Accommodation" subtitle="Powered Sites, Cabins and Camp Sites available" />
      <BookingPolicySection>
        <img src={CampEntry} alt="Camp Entry" />
        <div>
          <Subheading>Booking Policy</Subheading>
          <ul>
            <li>All reservations must be paid in full on arrival</li>
            <li>
              Please notify if arrival after 6pm so alternate plans can be made for pickup of keys
            </li>
            <li>
              If unable to arrive on due date, please notify for alternate plans or refund if needed
            </li>
            <li>Arrival time is 2pm and Departure time 10am</li>
          </ul>

          <OutboundButton href="/#booking">Book now</OutboundButton>
        </div>
      </BookingPolicySection>

      <PoweredSitesSection>
        <div className="text-content">
          <Subheading>Powered Sites</Subheading>
          <p>
            Peterborough Caravan Park has large drive thru powered sites for all types of Caravans,
            Camper trailers, Goosenecks or Buses. Great place to stop over or stay to explore our
            region. Great camp kitchen, free Wifi, clean amenities, disabled facilities, and coin
            operated laundry. Unpowered sites are also available for those who are self-sufficient.
          </p>
          <br />
          <b>
            Note: Pets are permitted on powered sites in your own accommodation. They must always be
            on a leash and not left unattended.
          </b>
        </div>
        <img src={PoweredSitesImage} alt="Powered Sites" />
      </PoweredSitesSection>

      <CabinSection>
        <img src={CabinsOverviewImage} alt="Cabins" />
        <div>
          <div className="cabin-description">
            <Subheading>Cabins</Subheading>
            <p>Peterborough Caravan Park has a number of nice cabins available for booking.</p>
          </div>
          <Subheading>Features include:</Subheading>
          <GridList>
            <div>
              <ul>
                <li>Air Conditioning</li>
                <li>Cooking Facilities</li>
                <li>Crockery/Cutlery</li>
                <li>Free Wi-Fi</li>
                <li>Microwave</li>
              </ul>
            </div>

            <div>
              <ul>
                <li>Parking</li>
                <li>Refrigerator</li>
                <li>Tea &amp; Coffee Facilities</li>
                <li>TV</li>
              </ul>
            </div>
          </GridList>
          <br />
          <b>
            Note: We have a pet friendly cabin. Please advised when booking if your pet is staying
            with us. No Smoking in Cabins
          </b>
        </div>
      </CabinSection>

      <ContentSection>
        <Subheading>Pick from the following:</Subheading>
        <ImageGrid3Cabin array={subCabins} />
      </ContentSection>

      <CabinSection>
        <img src={CampsiteOverview_Img} alt="CampSites" />
        <div>
          <div className="cabin-description">
            <Subheading>Camp Sites</Subheading>
            <p>
              Peterborough Caravan Park has powered or unpowered sites available. Great areas of
              grass or gravel for the keen camper. Tents, swags, roof top campers or camper trailers
              are secluded from the drive through sites.
            </p>
            <br />
            <p>
              Bon fire pit is available April to October, NOT to be used during fire ban season.
            </p>
          </div>
        </div>
      </CabinSection>
    </Layout>
  );
};

export default AccommodationPage;
